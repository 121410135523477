import { render, staticRenderFns } from "./PolicyEdit.vue?vue&type=template&id=6613a228&scoped=true"
import script from "./PolicyEdit.vue?vue&type=script&lang=js"
export * from "./PolicyEdit.vue?vue&type=script&lang=js"
import style0 from "./PolicyEdit.vue?vue&type=style&index=0&id=6613a228&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6613a228",
  null
  
)

export default component.exports