<template>
  <div class="container_margin">
    <div class="row">
      <div class="col-sm-12 col-md-4 col-lg-4 col-xl-8 mb-1"></div>
      <div class="col-sm-6 col-md-4 col-lg-4 col-xl-2 mb-1">
        <button class="btn btn-primary-outlined w-100 float-right">
          {{ "Cancel" }}
        </button>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-4 col-xl-2 mb-1">
        <button class="btn btn-primary w-100 float-right">
          {{ "Update Changes" }}
        </button>
      </div>
    </div>

    <div class="row mt-1">
      <div class="col">
        <div class="card border shadow-sm p-3 mt-3 rounded">
          <div class="row">
            <div class="col">
              <h5 class="font-weight-bold">{{ "Policy Details" }}</h5>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-4">
              <b>{{ "Name" }}</b>
            </div>
            <div class="col-8">
              <b-form-input
                autocorrect="off"
                autocomplete="off"
                v-model="name"
                type="text"
                id="name"
                name="name"
              />
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-4">
              <b>{{ "Description" }}</b>
            </div>
            <div class="col-8">
              <b-form-textarea
                id="description"
                v-model="description"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col">
        <div class="card border shadow-sm p-3 mt-3 rounded">
          <div class="row">
            <div class="col">
              <h5 class="font-weight-bold">
                {{ "Permissions" }}
              </h5>
            </div>
            <div class="col-4">
              <p class="text-blue-underline float-right">{{ "Toggle All" }}</p>
            </div>
          </div>

          <div class="row mt-4">
            <div
              class="col-4 mb-2"
              v-for="(item, index) in all_available_permissions"
              :key="'all_available_permissions' + index"
            >
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  :id="item.id"
                  v-model="selected_permissions"
                  :value="item.id"
                />
                <label class="form-check-label" :for="item.id">
                  <span class="text-secondary">{{ item.description }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  data() {
    return {
      name: "", // Policy name
      description: "", // Policy description
      all_available_permissions: [], // Array to hold available permissions
      selected_permissions: [], // Array to store selected permissions
    };
  },
  methods: {
    updateChanges() {
      // Method to handle updating policy changes
    },
    cancelChanges() {
      // Method to handle canceling changes
    },
    toggleAllPermissions() {
      // Method to handle toggling all permissions
    },
  },
};
</script>

<style scoped>
.container_margin {
  padding: 2rem;
}
</style>